import React, { useState, useContext } from "react";
import "./style.css";
import logo from "./logo.png";
import Button from "components/common/Button";
import ButtonGroup from "components/common/ButtonGroup";
import TextInput from "components/common/TextInput";
import Validation from "components/common/Validation";
import { CompanyContext } from "lib/context/CompanyContext";
// import Identity from "lib/api/mock/Identity.js";
import Session from "lib/api/Session.js";
import { StatusMessageContext } from "lib/context/StatusMessageContext";
export default function Login(props) {
  const [email, setEmail] = useState("");
  const [secret, setSecret] = useState("");
  const [error, setError] = useState();
  const { message, setMessage } = useContext(StatusMessageContext);
  const { company, setCompany } = useContext(CompanyContext);
  const params =
    props.location.state && props.location.state.from
      ? props.location.state.from.search
      : "";
  async function submit() {
    var response = await Session.create({
      email: email,
      secret: secret,
    });
    console.log(response);
    switch (response.status) {
      case 201:
        sessionStorage.setItem("token", response.data.token);
        if (!company.uuid && response.data.user.companies.length) {
          setCompany(response.data.user.companies[0]);
        } else {
          if (
            company.uuid &&
            !response.data.user.companies.find((user_company) => {
              return user_company.uuid == company.uuid;
            })
          ) {
            setCompany(null);
          }
        }
        if (props.location.state && props.location.state.from) {
          let url =
            props.location.state.from.pathname +
            props.location.state.from.hash +
            props.location.state.from.search;
          props.history.push(props.location.state.from);
        } else {
          props.history.push(`/dashboard`);
        }
        break;
      default:
        setError("Something went wrong. Please try again.");
    }
  }
  var errorMessage, contextMessage;
  if (message) {
    contextMessage = <Validation className="success">{message}</Validation>;
  }
  if (error) {
    errorMessage = <Validation className="danger">{error}</Validation>;
  }
  return (
    <div className="app">
      <div className="app-body">
        <div className="app-body__content app-body__content--gradient">
          <div className="app-body__page app-body__page--sm">
            <div className="m-bot-auto m-top-auto">
              <div className="row m-top-20">
                <div className="col-xs-12 center-xs m-bot-20">
                  <img src={logo} alt="logo" />
                </div>
              </div>
              <div className="row">
                  <div className="col-xs-12 text-center">
                    <p>
                      This portal is no longer available. If you are still using the ExpenseEase app you need to update to the new ComputerEase Field app in the app store. If you need additional assistance you can reach out to your ComputerEase Administrator.
                    </p>
                  </div>
                </div>
              <div
                id="invitation-message"
                className="validation validation--attention validation--expanded center-xs m-top-20"
              >
                <div className="row">
                  <div className="col-xs-12">
                    <h3>Download ComputerEase Field</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-6">
                    <div className="button-group button-group--sm button-group--flex button-card">
                      <a
                        className="button"
                        href="https://apps.apple.com/us/app/expenseease/id1269250889"
                        target="_blank"
                      >
                        On App Store
                      </a>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <div className="button-group button-group--sm button-group--flex button-card">
                      <a
                        className="button"
                        href="https://play.google.com/store/apps/details?id=com.ceexpenseapp"
                        target="_blank"
                      >
                        On Google Play Store
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {contextMessage}
              {errorMessage}        
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
