export default {
  create: async function (payload) {
    const authToken = await sessionStorage.getItem("token");
    const request = new Request(`${process.env.REACT_APP_API_BASE_URL}/user`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload),
    });
    const response = await fetch(request);
    return await response.json();
  },
  forgotPassword: async function (payload) {
    const request = new Request(
      `${process.env.REACT_APP_API_BASE_URL}/user/forgot-password`,
      {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(payload),
      }
    );
    const response = await fetch(request);
    return await response.json();
  },
  resetPassword: async function (payload) {
    const request = new Request(
      `${process.env.REACT_APP_API_BASE_URL}/user/reset-password`,
      {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(payload),
      }
    );
    const response = await fetch(request);
    return await response.json();
  },
  update: async function (uuid, user) {
    const authToken = await sessionStorage.getItem("token");
    const request = new Request(
      `${process.env.REACT_APP_API_BASE_URL}/system/user/${uuid}`,
      {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: authToken,
        }),
        body: JSON.stringify(user),
      }
    );
    const response = await fetch(request);
    return await response.json();
  },
  verify: async function (uuid, payload) {
    const request = new Request(
      `${process.env.REACT_APP_API_BASE_URL}/user/${uuid}/verify`,
      {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(payload),
      }
    );
    const response = await fetch(request);
    return await response.json();
  },
  addCompany: async function (payload) {
    const auth_token = await sessionStorage.getItem("token");
    console.log("accepting company");
    console.log(payload);
    const request = new Request(
      `${process.env.REACT_APP_API_BASE_URL}/company-invite/accept`,
      {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: auth_token,
        }),
        body: JSON.stringify(payload),
      }
    );
    const response = await fetch(request);
    return await response.json();
  },
  // getInvite: async function (confirmation) {
  //   const auth_token = await sessionStorage.getItem("token");
  //   console.log("accepting company");
  //   console.log(payload);
  //   const request = new Request(
  //     `${process.env.REACT_APP_API_BASE_URL}/company-user?confirmation_token={${confirmation_token}}`,
  //     {
  //       method: "POST",
  //       headers: new Headers({
  //         "Content-Type": "application/json",
  //         Authorization: auth_token
  //       }),
  //       body: JSON.stringify(payload),
  //     }
  //   );
  //   const response = await fetch(request);
  //   return await response.json();
  // },
  // /forgot-password
  // /reset-password
};
