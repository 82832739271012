import React, { useState, useEffect, useContext } from "react";
import ButtonGroup from "components/common/ButtonGroup";
import Button from "components/common/Button";
import Validation from "components/common/Validation";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { CompanyContext } from "lib/context/CompanyContext";
import Session from "lib/api/Session.js";
import Company from "lib/local/Company";
// import Identity from "lib/api/mock/Identity.js";
export default function CompanyList({ setShowModal }) {
  const [companies, setCompanies] = useState([]);
  const { company, setCompany } = useContext(CompanyContext);
  const [error, setError] = useState();
  async function load() {
    let response = await Session.get();
    console.log(response);
    switch (response.status) {
      case 200:
        setCompanies(response.data.user.companies);
        break;
      default:
        setError("Something went wrong. Please try again.");
    }
  }

  useEffect(() => {
    load();
  }, []);
  const companyList = companies.map((company) => (
    <div key={company.uuid} className="row">
      <div className="col-xs-12">
        <ButtonGroup className="button-group--lg button-group--flex button-card">
          <Button
            className="login-toggle"
            onClick={(e) => {
              setCompany(company);
              setShowModal(false);
            }}
          >
            <span className="login-toggle__gradient"></span>
            <span className="login-toggle__creds">
              <span className="creds--client-name">{company.client.name}</span>
              <span className="creds--company-name">{company.name}</span>
            </span>
          </Button>
        </ButtonGroup>
      </div>
    </div>
  ));
  return (
    <div className="app-body__page">
      <h1>My Companies</h1>
      <div className="hr hr--darkest"></div>
      {error}
      {/*
      <Validation className="success">
        Company Name has been connected to your account.
      </Validation>
      */}
      {companyList}
      <div className="hr hr--darkest m-bot-20"></div>
      <div className="row middle-xs">
        <div className="col-xs-4">
          <ButtonGroup>
            <Button
              onClick={() => {
                setShowModal(false);
              }}
            >
              Close
            </Button>
          </ButtonGroup>
        </div>
        <div className="col-xs-8 end-xs">
          <p className="p--small">
            <Link to="#connect">Connect Company</Link>
          </p>
        </div>
      </div>
    </div>
  );
}
