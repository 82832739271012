import React, { useState, useEffect, useContext } from "react";
import ButtonGroup from "components/common/ButtonGroup";
import Button from "components/common/Button";
import TextInput from "components/common/TextInput";
import Validation from "components/common/Validation";
import FieldEase from "lib/api/mock/FieldEase.js";
import { CompanyContext } from "lib/context/CompanyContext";
import Company from "lib/local/Company";
import { useHistory } from "react-router-dom";
import Identity from "lib/api/mock/Identity.js";
export default function CompanyConnect() {
  const [error, setError] = useState();
  const [clientId, setClientId] = useState("");
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const { companies, setCompanies } = useContext(CompanyContext);
  let history = useHistory();
  async function submit() {
    // Company.create({
    //   name: username,
    //   client: client
    // });
    const results = await Identity.connect(clientId, userId, password);
    history.goBack();
    // var response = await FieldEase.connect();
    // console.log(response);
    // switch (response.statusCode) {
    //   default:
    //     //var newSet =  companies.push({id:Date.now() ,name:"test company", client:"new client"})
    //     //setCompanies([...companies, ...[{name:"test company", client:"new client"}]])
    //     setCompanies([
    //       { id: 1, name: "test", client: "test client" },
    //       { id: 2, name: "test2", client: "test client2" }
    //     ])
    //     history.goBack();
    //     //setError("Something went wrong. Please try again.");
    // }
  }
  return (
    <div className="app-body__page">
      <h1>Add Company</h1>
      <div className="hr hr--darkest"></div>
      <div className="row">
        <div className="col-xs-12">
          <h2>Legacy FieldEase Account</h2>
          <p>
            Enter your legacy FieldEase credentials to connect your account and
            make the company accessible through 'My Companies'.
          </p>
        </div>
      </div>
      <div className="hr m-top-10"></div>
      <div className="row">
        <div className="col-xs-12">
          <TextInput
            label="Client ID"
            placeholder="Client ID"
            type="text"
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
          />
        </div>
        <div className="col-xs-12">
          <TextInput
            label="Username"
            placeholder="Username"
            type="text"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
        </div>
        <div className="col-xs-12">
          <TextInput
            label="Password"
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Validation className="danger">
            The credentials entered are invalid.
          </Validation>
        </div>
      </div>
      <div className="hr hr--darkest m-top-10 m-bot-20"></div>
      <div className="row middle-xs">
        <div className="col-xs-4">
          <ButtonGroup>
            <Button
              onClick={(event) => {
                submit();
              }}
            >
              Connect
            </Button>
            <Button
              className="button--plain"
              onClick={(event) => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
}
