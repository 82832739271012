import React, { useState, useEffect, useContext } from "react";
import CompanyList from "./CompanyList";
import CompanyInvite from "./CompanyInvite";
import CompanyConnect from "./CompanyConnect";
import { useLocation } from "react-router-dom";
export default function CompanyModal(props) {
  let { hash } = useLocation();
  if (!hash) {
    hash = "";
  }
  switch (hash) {
    case "#FEConnect":
      return <CompanyConnect {...props} />;
      break;
    case "#connect":
      return <CompanyInvite {...props} />;
      break;
    default:
      return <CompanyList setShowModal={props.setShowModal} />;
  }
}
