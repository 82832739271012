import React, { useState, useEffect, useContext } from "react";
import logo from "./logo.png";
import "./style.css";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

import ButtonGroup from "components/common/ButtonGroup";
import Button from "components/common/Button";
import LeftNavLink from "components/common/LeftNavLink";
import Modal from "components/common/Modal";
import TextInput from "components/common/TextInput";
import CompanyModal from "components/company/CompanyModal";
import { CompanyContext } from "lib/context/CompanyContext";

// let toggle = document.querySelector('#toggle'),
//     sidebar = document.querySelector('#sidebar');
//
// toggle.addEventListener('click', function(e) {
//     e.preventDefault();
//     sidebar.classList.toggle('app-body__left-nav--open');
// });

export default function PageLayout(props) {
  const [showModal, setShowModal] = useState(false);
  const { company, setCompany } = useContext(CompanyContext);
  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };
  // async function load() {
  //   if (!company.uuid) {
  //     console.log("NO COMPANY");
  //     var companies = await Identity.companies();
  //     console.log(companies);
  //     if (companies.length) {
  //       setCompany(companies[0]);
  //     } else {
  //       setShowModal(true);
  //     }
  //   }
  // }
  useEffect(() => {
    if (
      props.location &&
      props.location.hash &&
      props.location.hash == "#connect"
    ) {
      setShowModal(true);
    }
  }, []);
  return (
    <div className="app">
      <div className="app-header">
        <div onClick={handleToggle} className="app-header__menu">
          &#9776;
        </div>
        <div className="app-header__logo">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className="app-body">
        <div
          className={`app-body__left-nav ${
            !isActive ? "app-body__left-nav--toggled" : ""
          }`}
        >
          <Modal show={showModal}>
            <CompanyModal setShowModal={setShowModal} {...props} />
          </Modal>
          <button
            className="login-toggle"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <span className="login-toggle__gradient"></span>
            <span className="login-toggle__creds">
              <span className="creds--company-name">
                {company ? company.name : "My Companies"}
              </span>
              <span className="creds--client-name">
                {company ? `${company.company_user.email}` : ""}
              </span>
            </span>
          </button>
          <NavLink to={`/dashboard`}>
            <LeftNavLink linkLabel="Dashboard" />
          </NavLink>
          <NavLink to={`/expenses`}>
            <LeftNavLink linkLabel="Submitted Expenses" />
          </NavLink>
          {/*
                <NavLink to={`/timesheets`}>
            <LeftNavLink linkLabel="Timesheets" />
          </NavLink>
          <NavLink to={`/workorders`}>
            <LeftNavLink linkLabel="Work Orders" />
          </NavLink>
          <LeftNavLink
            linkLabel="Purchase Orders"
            linkClass="left-nav__link--disabled"
          />
          <LeftNavLink
            linkLabel="Invoice Approval"
            linkClass="left-nav__link--disabled"
          />
          <LeftNavLink
            linkLabel="Field Logs"
            linkClass="left-nav__link--disabled"
          />
          <LeftNavLink
            linkLabel="CE Reports"
            linkClass="left-nav__link--disabled"
          />
          <LeftNavLink
            linkLabel="FE Reports"
            linkClass="left-nav__link--disabled"
          />
          <LeftNavLink
            linkLabel="Personal Notes"
            linkClass="left-nav__link--disabled"
          />
          <LeftNavLink linkLabel="Alerts" linkClass="left-nav__link--disabled" />
          <LeftNavLink linkLabel="Settings" linkClass="left-nav__link--disabled" />
          <LeftNavLink linkLabel="Expenses" linkClass="left-nav__link--disabled" />
          <LeftNavLink linkLabel="Documents" linkClass="left-nav__link--disabled" />
          */}
          {/* <NavLink to={`/users`}>
            <LeftNavLink linkLabel="User Maintenance" />
          </NavLink> */}
          {/*
          <NavLink to={`/roles`}>
            <LeftNavLink linkLabel="Permission Presets" />
          </NavLink>
          */}
          <NavLink to={`/profile`}>
            <LeftNavLink linkLabel="My Account" />
          </NavLink>
          <NavLink to={`/logout`}>
            <LeftNavLink linkLabel="Logout" />
          </NavLink>
        </div>
        <div className="app-body__content">
          <div className="app-body__page">{props.children}</div>
        </div>
      </div>
    </div>
  );
}
