const UserGroup_URL =
  "https://prodsupport.fieldease.com/Services/DataService.svc/GetUserGroup_JSON?pstrUserKey=13283&pstrClientID=peter&pstrCompanyID=631";
const TimeSheet_URL =
  "https://prodsupport.fieldease.com/Services/DataService.svc/GetTimeSheet_JSON?pstrClientID=peter&pstrCompanyID=631&pstrSubmittedInd=0&pstrExportedInd=0&pstrTimeSheetEntryTypeKey=1&pstrWeekEndingDate=12%2F21%2F2019&pstrUserTypeKey=2&pstrEmployeeID=33&pstrGroupEntryInd=1&pstrUserKey=13283&pstrViewMyEntriesOnlyInd=0";
const Employee_URL =
  "https://prodsupport.fieldease.com/Services/DataService.svc/GetEmployee_JSON?pstrClientID=peter&pstrCompanyID=631&pstrGroupID=1&pstrGroupEntryInd=1&pstrEmployeeID=33";
const Company_URL =
  "https://prodsupport.fieldease.com/Services/DataService.svc/GetCompany_JSON?pstrClientID=peter";
const UserSettings_URL =
  "https://prodsupport.fieldease.com/Services/DataService.svc/GetUserData_JSON?pstrUserKey=13283";
// const TimeSheetDailyDefaultField_URL = 'https://prodsupport.fieldease.com/Services/DataService.svc/GetTimeSheetDailyDefaultField_JSON?pstrClientID=peter&pstrAdminUserID=admin'
// const TimeSheetTypeSetting_URL = 'https://prodsupport.fieldease.com/Services/DataService.svc/GetTimeSheetTypeSetting_JSON?pstrUserKey=13283&_=1576273697176'
// const Category_URL = 'https://prodsupport.fieldease.com/Services/DataService.svc/GetCategory_JSON?pstrClientID=peter&pstrCompanyID=631&pstrGroupID=1&pstrJobID=88&pstrPhaseID=015'
// const State_URL = 'https://prodsupport.fieldease.com/Services/DataService.svc/GetState_JSON?pstrClientID=peter&pstrCompanyID=631&pstrGroupID=1&_=1576273697184'
// const Locality_URL = 'https://prodsupport.fieldease.com/Services/DataService.svc/GetLocality_JSON?pstrClientID=peter&pstrCompanyID=631&pstrGroupID=1'
// const Class_URL = 'https://prodsupport.fieldease.com/Services/DataService.svc/GetClass_JSON?pstrClientID=peter&pstrCompanyID=631&pstrGroupID=1'
// const Department_URL = 'https://prodsupport.fieldease.com/Services/DataService.svc/GetDepartment_JSON?pstrClientID=peter&pstrCompanyID=631&pstrGroupID=1'
// const Equipment_URL = 'https://prodsupport.fieldease.com/Services/DataService.svc/GetEquipment_JSON?pstrClientID=peter&pstrCompanyID=631&pstrGroupID=1'
// const EquipmentCode_URL = 'https://prodsupport.fieldease.com/Services/DataService.svc/GetEquipmentCode_JSON?pstrClientID=peter&pstrCompanyID=631&pstrGroupID=1'
// const Crew_URL = 'https://prodsupport.fieldease.com/Services/DataService.svc/GetCrew_JSON?pstrClientID=peter&pstrCompanyID=631&pstrGroupID=1'
// const JOB_URL = 'https://prodsupport.fieldease.com/Services/DataService.svc/GetJob_JSON?pstrClientID=peter&pstrCompanyID=631&pstrGroupID=1'

export default {
  groups: async function () {
    return [];
  },
  employees: async function () {
    return [
      {
        uuid: "4f334ff3-b82f-4ad0-9a1b-827ebb9c2fbf",
        name: "Joe Burrow",
        reference_id: "JBurrow",
      },
      {
        uuid: "d24f1476-6bd5-4c2f-a951-5d502583ad4c",
        name: "Tom Brady",
        reference_id: "TBrady",
      },
      {
        uuid: "0e6ab707-1d89-4c4d-ad16-a852d6d3861e",
        name: "Russle Wilson",
        reference_id: "RWilson",
      },
    ];
  },
  timesheets: async function () {
    return [
      {
        employee: "Joe Burrow",
        start_time: 1600283149766,
        end_time: 1600283150766,
      },
      {
        employee: "Tom Brady",
        start_time: 1600283149766,
        end_time: 1600283150766,
      },
      {
        employee: "Russle Wilson",
        start_time: 1600283149766,
        end_time: 1600283150766,
      },
    ];
  },
};
