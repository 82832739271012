import React, { useState, useEffect, useContext } from "react";
import ButtonGroup from "components/common/ButtonGroup";
import Button from "components/common/Button";
import TextInput from "components/common/TextInput";
import Validation from "components/common/Validation";
import { useHistory } from "react-router-dom";
import User from "lib/api/User.js";
import { StatusMessageContext } from "lib/context/StatusMessageContext";
import { CompanyContext } from "lib/context/CompanyContext";
export default function CompanyInvite(props) {
  console.log(props);
  let history = useHistory();
  const [confirmation_token, setConfirmationToken] = useState("");
  const [company_user, setCompanyUser] = useState();
  const [error, setError] = useState();
  const { message, setMessage } = useContext(StatusMessageContext);
  const { company, setCompany } = useContext(CompanyContext);
  var successMessage, errorMessage;
  if (message) {
    successMessage = <Validation className="success">{message}</Validation>;
  }
  if (error) {
    errorMessage = <Validation className="danger">{error}</Validation>;
  }
  async function submit() {
    // if(!company_user){
    //   await load(confirmation_token);
    // }
    // const results = await User.addCompany(company_user.uuid, {confirmation_token: confirmation_token});
    const response = await User.addCompany({
      confirmation_token: confirmation_token,
    });
    console.log(response);
    console.log(props);

    switch (response.status) {
      case 200:
        setMessage("Company has been added.");
        console.log(company);
        console.log(response);
        setCompany(response.data.user.company);
        props.history.push(`/dashboard`);
        break;
      case 409:
        setError("can't add company");
        break;
      default:
        setError("Something went wrong. Please try again.");
    }
  }
  // async function load(confirmation_token){
  //   let company_user = await User.getInvite({confirmation_token: confirmation_token});
  //   setCompanyUser(company_user.company);
  // }
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(props.location.search);
    if (urlSearchParams.has("confirmation_token")) {
      setConfirmationToken(urlSearchParams.get("confirmation_token"));
      // load();
    }
    // if (urlSearchParams.has("company_user_uuid")) {
    //   setCompanyUserUuid(urlSearchParams.get("company_user_uuid"));
    // }
  }, []);
  return (
    <div>
      {successMessage}
      {errorMessage}
      <div className="row">
        <div className="col-xs-12">
          <h2>Connect Company</h2>
          <p>
            Please enter a company invitation code below to connect a company to
            your CEID account.
          </p>
        </div>
      </div>
      <div className="hr m-top-10"></div>
      <div className="row">
        <div className="col-xs-12">
          <TextInput
            label="Invitation Code"
            placeholder="Invitation Code"
            type="text"
            value={confirmation_token}
            onChange={(e) => setConfirmationToken(e.target.value)}
          />
        </div>
      </div>
      <div className="hr hr--darkest m-top-10 m-bot-20"></div>
      <div className="row middle-xs">
        <div className="col-xs-4">
          <ButtonGroup>
            <Button
              onClick={(event) => {
                submit();
              }}
            >
              Connect
            </Button>
            <Button
              className="button--plain"
              onClick={(e) => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
}
