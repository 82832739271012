export default {
  create: async function (email) {
    return {
      statusCode: 201,
      data: {
        message: `${email} has been ceated. Please verify email.`,
        token: "421644c1b91320faeeabaa24287efc0c",
      },
    };
  },
  verify: async function (confirmation_token, first_name, last_name, secret) {
    return {
      statusCode: 200,
      data: {
        token: "TESTTOKEN",
      },
    };
  },
  login: async function (email, secret) {
    return {
      statusCode: 201,
      data: {
        token: "testtoken",
      },
    };
  },
  logout: async function (email, secret) {
    return {
      statusCode: 200,
    };
  },
  companies: async function () {
    return [
      {
        uuid: "611e09ac-1ad3-4ced-b71b-7f1ab7eed631",
        name: "Bobs Construction",
        client: {
          uuid: "5ea467d3-2e9f-4eb4-8295-fec38b40e53f",
          name: "Bobs Corp",
        },
        reference_id: "BobsCon",
      },
      {
        uuid: "d070ef8d-55a0-4103-9eef-0c45910322e6",
        name: "BD Construction",
        client: {
          uuid: "5ea467d3-2e9f-4eb4-8295-fec38b40e53f",
          name: "Bobs Corp",
        },
        reference_id: "BDConstruction",
      },
    ];
  },
  connect: async function (client_id, user_id, password) {
    return {
      statusCode: 200,
    };
  },
  users: async function (company_uuid) {
    return [
      {
        uuid: "4f334ff3-b82f-4ad0-9a1b-827ebb9c2fbf",
        name: "Joe Burrow",
        reference_id: "JBurrow",
      },
      {
        uuid: "d24f1476-6bd5-4c2f-a951-5d502583ad4c",
        name: "Tom Brady",
        reference_id: "TBrady",
      },
      {
        uuid: "0e6ab707-1d89-4c4d-ad16-a852d6d3861e",
        name: "Russle Wilson",
        reference_id: "RWilson",
      },
    ];
  },
  getUser: async function (company_uuid, user_uuid) {
    return {
      statusCode: 200,
    };
  },
  createUser: async function (
    company_uuid,
    email,
    name,
    reference_id,
    settings
  ) {
    return {
      statusCode: 201,
      data: {
        message: `${name} has been ceated. Please verify email.`,
        token: "421644c1b91320faeeabaa24287efc0c",
      },
    };
  },
  acceptInvite: async function (confirmation_token) {
    return {
      statusCode: 201,
      data: {
        message: `Inventation accepted`,
      },
    };
  },
};
