import React from "react";
import "./style.css";
import LabelSpan from "../LabelSpan";
export default function TextInput({
  label,
  required,
  className,
  placeholder,
  type,
  value,
  onChange,
  disabled,
}) {
  const inputRequired = required ? (
    <span className="danger">&nbsp;*</span>
  ) : null;
  const inputLabel = label ? (
    <LabelSpan>
      {label}
      {inputRequired}
    </LabelSpan>
  ) : null;

  return (
    <label>
      {inputLabel}
      <input
        type="text"
        className={`${className}`}
        placeholder={`${placeholder}`}
        type={`${type}`}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </label>
  );
}
