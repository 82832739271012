import React from "react";
import "./style.css";
export default function Button({ className, children, onClick, disabled }) {
  return (
    <button
      className={`button ${className ? className : ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
