import React, { useState, useEffect, useContext } from "react";
import Session from "lib/api/Session.js";
import Validation from "components/common/Validation";
import PageLayout from "components/layouts/PageLayout";
import { CompanyContext } from "lib/context/CompanyContext";
export default function Logout(props) {
  const [error, setError] = useState();
  const { company, setCompany } = useContext(CompanyContext);
  async function logout() {
    try {
      var response = await Session.delete();
      console.log(response);
      switch (response.status) {
        case 200:
          await sessionStorage.removeItem("token");
          // setCompany(null);
          props.history.push("/login");
          // props.history.replace(`/login`);
          break;
        default:
          setError("Something went wrong. Please try again.");
      }
    } catch (exception) {
      console.log(exception);
      setError("Something went wrong. Please try again.");
    }
  }
  var message = <div>Logging out...</div>;
  if (error) {
    message = <Validation className="danger">{error}</Validation>;
  }
  useEffect(() => {
    logout();
  }, []);
  return <PageLayout {...props}>{message}</PageLayout>;
}
