import React from "react";
import "./style.css";
export default function Modal(props) {
  if (!props.show) {
    return null;
  }
  return (
    <div className="modal modal--sm">
      <div className="app-body__content">{props.children}</div>
    </div>
  );
}
